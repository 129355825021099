import { TEACHERS_Q } from '@/graphql/queries'

export default {
  methods: {
    getTeachers() {
      this.$apollo.query({
        query: TEACHERS_Q,
      }).then(res => {
        this.$store.commit('termsClasses/setTeachers', res.data.teachers)
      }).catch(error => {
      })
    },
    getUsers() {
      this.$http.get("accounts/users").then(res => {
        this.$store.commit('accountUsers/setUsers', {users:res.data})
      }).catch(error => {
      })
    },
    getVehicles() {
      this.$http.get("transportation/vehicles").then(res => {
        this.$store.commit('vehicle/setVehicles', res.data)
      }).catch(error => {
      })
    },
    getPickupPoints() {
      this.$http.get("transportation/pickup-points").then(res => {
        this.$store.commit('pickupPoint/setPickupPoints', res.data)
      }).catch(error => {
      })
    },
  },
}
